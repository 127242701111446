import baguetteBox from 'baguettebox.js';
import { toggleClass } from './mobileNavigation.js';




document.addEventListener("DOMContentLoaded", () => {
  'use strict';
  toggleClass('.nav-trigger.nav-open-trigger', '.nav-open .nav-trigger.nav-close-trigger');


    // listing articles als ganzes klickbar machen
  let listArticles = document.querySelectorAll("[data-article-href]");
  listArticles.forEach(function(article){
      article.classList.add('clickable');
      let articleLink = article.dataset['articleHref'];
      article.addEventListener('click',function(event){
        window.location = articleLink;
      });
  });



  let scrollchecker = document.createElement("div")
  scrollchecker.classList.add("scrollobserver");

  let header = document.querySelector("#header");
  header.parentNode.insertBefore(scrollchecker,header);


  let observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if(entry.intersectionRatio != 1) {
        document.body.classList.add("not-at-top");
      } else {
        document.body.classList.remove("not-at-top");
      }
    });
  }, {  threshold: 1  });
  observer.observe(scrollchecker)


  let visibleObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if(entry.intersectionRatio != 0.1) {
        entry.target.classList.remove("in-viewport");
      } else {
        entry.target.classList.add("in-viewport");
      }
    });
  }, {  threshold: 0.5  });

  document.querySelectorAll(".viewportobserve").forEach((element) => {
    visibleObserver.observe(element)
  });

});

;(function(window) {
  'use strict';
  baguetteBox.run('.content-gallery, .content-image a[data-lightbox], .content-text a[data-lightbox]  ', {
    animation: 'fadeIn',
    noScrollbars: false
  });
})(window);
